import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  headerShown = signal<boolean>(true);
  footerShown = signal<boolean>(true);
  circularNavShown = signal<boolean>(true);
  getStartedAsShown = false;

  constructor() {}

  showHeader() {
    this.headerShown.set(true);
  }
  hideHeader() {
    this.headerShown.set(false);
  }
  showFooter() {
    this.footerShown.set(true);
  }
  hideFooter() {
    this.footerShown.set(false);
  }
  showCircularNav() {
    this.circularNavShown.set(true);
  }
  hideCircularNav() {
    this.circularNavShown.set(false);
  }
  showGetStartedAs() {
    this.getStartedAsShown = true;
  }
  hideGetStartedAs() {
    this.getStartedAsShown = false;
  }
}
